import {makeAutoObservable, runInAction} from "mobx";
import { DocumentFormat, PortalService } from "../../services/PortalService";
import { debounce, DebouncedFunc } from "lodash";
import { SmartFormEventService } from "../../services/SmartFormEventService";

export interface ITemplate{
    id: string;
    name: string;
    executionUrl: string;
}

export interface IAction{
    title: string;
    callback: () => void;
}

export class TemplateStore {
    hasTemplateParameter: boolean = true;
    mobileClass: string = "show-form";
    zoomLevel: number = 100;
    mainAction: IAction;
    html: string | undefined;
    isOpen: boolean = false;
    isLoading: boolean = false;
    templates: ITemplate[] = [];
    seletectTemplate: ITemplate | undefined;
    private MaxZoomValue: number = 350;
    private ZoomStep: number = 10;
    private markedHtml: string | undefined;
    private needToExecute: boolean = false;
    private portalService: PortalService;
    private smartFormService: SmartFormEventService;
    private debounceFunction: DebouncedFunc<() => Promise<void>> | undefined;

    constructor(portalService: PortalService, smartFormEventService: SmartFormEventService){
        makeAutoObservable(this);
        this.portalService = portalService;
        this.smartFormService = smartFormEventService;

        this.downloadPDFAsync = this.downloadPDFAsync.bind(this);
        this.downloadWordAsync = this.downloadWordAsync.bind(this);
        this.download = this.download.bind(this);
        this.withLoading = this.withLoading.bind(this);

        this.mainAction = {title:"Download PDF", callback: this.downloadPDFAsync};
    }
    
    public enableEventListener(portal: HTMLIFrameElement){
        this.smartFormService.enableEventListener(portal);

        this.smartFormService.subscribe("FieldValueChanged", async (m) => {
            await this.executeTemplateAsync();
        });
    }

    public async loadTemplates() {
        this.templates = await this.portalService.fetchTemplatesAsync();
        
        if (this.templates.length > 0){
            const templateParameter = this.getTemplateUrlParameter();
            if (templateParameter){
                const index = this.templates.findIndex(t => t.name === templateParameter);
                if (index !== -1){
                    this.seletectTemplate = this.templates[index];
                    return;
                }
            }
            runInAction(() => {
                this.hasTemplateParameter = false;
                this.seletectTemplate = this.templates[0];
            });
        }
    }

    public selectTemplate(template: ITemplate){
        this.seletectTemplate = template;
    }

    public toggleStore() {
        this.isOpen = !this.isOpen;
    }

    public setZoomLevel(level: string){
        let value = parseInt(level);
        if (value > this.MaxZoomValue){
            value = this.MaxZoomValue;
        }

        this.zoomLevel = value;
        this.replaceZoomMarker();
    }

    public decreaseZoomLevel(){
        if (this.zoomLevel <= this.ZoomStep){
            return;
        }

        this.zoomLevel = this.zoomLevel - this.ZoomStep;
        this.replaceZoomMarker();
    }

    public increaseZoomLevel(){
        if (this.zoomLevel > this.MaxZoomValue - this.ZoomStep){
            return;
        }

        this.zoomLevel = this.zoomLevel + this.ZoomStep;
        this.replaceZoomMarker();
    }

    public async downloadPDFAsync(){
        this.mainAction = {title:"Download PDF", callback: this.downloadPDFAsync};
        await this.withLoading(this.download("Pdf"));
    }

    public async downloadWordAsync(){
        this.mainAction = {title:"Download Word", callback: this.downloadWordAsync};
        await this.withLoading(this.download("Word"));
    }

    private async executeTemplateAsync(){
        if (this.seletectTemplate){
            if (this.debounceFunction){
                this.needToExecute = true;
                return;
            }

            this.debounceFunction = debounce(async () => {
                if (this.debounceFunction){
                    runInAction(() => {
                        this.isLoading = true;
                    });
                }
            }, 800);

            this.debounceFunction();
            var executionData = await this.smartFormService.getXmlDataAsync();
            this.markedHtml = await this.portalService.executeTemplate(this.seletectTemplate.id, executionData);
            this.debounceFunction = undefined;
            
            if (this.needToExecute){
                this.needToExecute = false;
                await this.executeTemplateAsync();
            } else{
                this.replaceZoomMarker();
                runInAction(() => this.isLoading = false);
            }
        }
    }

    private async download(format: DocumentFormat){
        if (this.seletectTemplate){
            const templateName = this.seletectTemplate.name;
            const fileName = format === "Pdf" ? templateName + ".pdf" : templateName + ".docx";
            const executionData = await this.smartFormService.getXmlDataAsync();
            await this.portalService.downloadTemplate({executionData, format, templateId: this.seletectTemplate.id}, fileName);
        }
    }

    private async withLoading(callback: Promise<void>){
        runInAction(() => this.isLoading = true);
        await callback;
        runInAction(() => this.isLoading = false);
    }

    private replaceZoomMarker(){
        if (this.markedHtml){
            this.html = this.markedHtml.replace("[ZoomMarker]", this.zoomLevel.toString() + "%");
        }
    }

    private getTemplateUrlParameter(): string | null{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('template');
    }
}