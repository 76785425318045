import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { RootStoreContext } from "../../App";

const TemplateDownload = observer(() => {
    const context = useContext(RootStoreContext);
    if (!context){
        return <React.Fragment/>;
    }

    const [dropdownOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);

    return (<div className="download-actions">
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <Button id="caret" color="primary" onClick={() => context.templateStore.mainAction.callback()}>{context.templateStore.mainAction.title}</Button>
            <DropdownToggle split color="primary" />
            <DropdownMenu>
                <DropdownItem header>Other formats</DropdownItem>
                <DropdownItem onClick={() => context.templateStore.downloadWordAsync()}>Download Word</DropdownItem>
                <DropdownItem onClick={() => context.templateStore.downloadPDFAsync()}>Download PDF</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    </div>);
});

export default TemplateDownload;