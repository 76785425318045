import lottie, { AnimationItem } from "lottie-web";
import React from "react";

import "./LottieAnimation.scss";

interface LottieProps{
    height: number;
    width: number;
    animationData: any;
    loop?: boolean;
    autoPlay?: boolean;
    description?: string;
    animationDidMount?: (animation: AnimationItem) => void;
}

const LottieAnimation: React.FC<LottieProps> = ({ animationData, loop, autoPlay, height, width, description, animationDidMount }: LottieProps) => {
    const divRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        if (divRef && divRef.current) {
            const element = divRef.current;
            const animation = lottie.loadAnimation({
                container: element,
                renderer: "svg",
                loop: loop === undefined ? false : loop,
                autoplay: autoPlay === undefined ? false : autoPlay,
                animationData,
            });

            if (animationDidMount) {
                animationDidMount(animation);
            }
        }
    }, [animationData, loop, autoPlay, animationDidMount]);

    return (
    <div className="animation-overlay">
        <div className="lottie-animation" style={{ height, width }} ref={divRef} />
        <div>{description}</div>
    </div>);
};

export default LottieAnimation;