interface IEventListener {
    eventName: string;
    callback: (message: IEventMessage) => void;
}

export interface IEventMessage {
    eventName: string;
    message?: any;
}

export class SmartFormEventService {
    private htmlElement: HTMLIFrameElement | undefined;
    private subscriptions: IEventListener[];

    constructor() {
        this.subscriptions = [];
    }

    /**
     * Registers the event
     * @param eventName
     * @param callback
     */
    public subscribe(eventName: string, callback: (message: IEventMessage) => void): void {
        this.subscriptions.push({ eventName, callback });
    }

    /**
     * Unsubscribes the message.
     * @param messageName
     */
    public unsubscribe(messageName: string): void {
        for (let index = this.subscriptions.length - 1; index >= 0; index--) {
            const element = this.subscriptions[index];
            if (element.eventName === messageName) {
                this.subscriptions.splice(index, 1);
            }
        }
    }

    public getXmlDataAsync(): Promise<string>{
        return new Promise<string>(r => {
            this.subscribe("Metadata", (m) => {
                this.unsubscribe("Metadata");
                r(m.message.value);
            });
            this.postMessage({eventName: "GetMetadata"});
        });
    }

    /**
     * Posts the given message to the frame.
     * @param message The message to post
     */
    public postMessage(message: IEventMessage): void {
        if (!this.htmlElement){
            throw new Error("Must call 'enableEventListener' first");
        }

        this.htmlElement.contentWindow?.postMessage(JSON.stringify(message), "*");
    }

    /**
     * Enable Event Listener
     *
     * @private
     * @memberof PortalEventService
     */
    public enableEventListener(element: HTMLIFrameElement): void {
        this.htmlElement = element;
        const eventListener = window["addEventListener"];

        eventListener("message", (e: any) => {
            if (e.source !== window) {
                const message = JSON.parse(e.data) as IEventMessage;
                this.subscriptions.forEach((event) => {
                    if (message.eventName === event.eventName) {
                        event.callback(message);
                    }
                });
            }
        }, false);
    }
}