import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react"
import { RootStoreContext } from "../../App";
import PortalFrame from "../portal-frame/PortalFrame";
import LoadingAnimation from "../../assets/lottie/loading_animation.json";
import LottieAnimation from "../lottie-animation/LottieAnimation";
import TemplateDownload from "../template-download/TemplateDownload";
import ZoomPanel from "../zoom-panel/ZoomPanel";

import "./Demo.scss";

const Demo = observer(() => {
    const portalRef = React.createRef<HTMLIFrameElement>();
    const context = useContext(RootStoreContext);

    useEffect(() => {
        context?.templateStore.loadTemplates();
        if (portalRef.current) {
            context?.templateStore.enableEventListener(portalRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!context) {
        return <React.Fragment />;
    }

    const loading = context.templateStore.isLoading ? <LottieAnimation height={60} width={60} animationData={LoadingAnimation} autoPlay={true} loop={true} description={"Previewing..."} /> : <React.Fragment />;
    return (
        <section className={"main " + context.templateStore.mobileClass}>
            <div className="portal-frame-wrapper">
                <section className="portal-frame">
                    <PortalFrame />
                </section>
            </div>
            <div className="preview-frame-wrapper">
                <section className="preview-frame">
                    {loading}
                    <iframe title={"Preview"} srcDoc={context.templateStore.html} />

                    <div className="action-bar">
                        <ZoomPanel />
                        <TemplateDownload />
                    </div>
                </section>
            </div>
        </section>);
});

export default Demo;