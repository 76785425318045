import React, { PropsWithChildren, useContext } from 'react';
import { NavMenu } from './NavMenu';

import { Button } from 'reactstrap';
import { observer } from 'mobx-react';
import { RootStoreContext } from '../App';
import { runInAction } from 'mobx';

const Layout = observer((props: PropsWithChildren<{}>) => {
  const context = useContext(RootStoreContext);
  if (!context) {
    return <React.Fragment />;
  }
  const setMobileClass = (value: string) => {
    runInAction(() => context.templateStore.mobileClass = value);
  };
  return (
    <React.Fragment>
      <NavMenu />
      <section className={"mobile-wrapper " + context.templateStore.mobileClass}>
          <div className="toggle-preview"><Button onClick={() => setMobileClass("show-preview")}>Preview <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" /></svg></Button></div>
          <div className="toggle-form"><Button onClick={() => setMobileClass("show-form")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" /></svg> Form</Button></div>
      </section>
      {props.children}
    </React.Fragment>
  );
});

export default Layout;
