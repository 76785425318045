import { observer } from "mobx-react";
import React, { useContext } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { RootStoreContext } from "../../App";

const TemplateDropdown = observer(() => {
  const context = useContext(RootStoreContext);
  if (!context || context.templateStore.hasTemplateParameter){
    return <React.Fragment/>;
    }

  const dropdownItems = context.templateStore.templates.map(t =><DropdownItem key={t.name} onClick={() => context.templateStore.selectTemplate(t)}>{t.name}</DropdownItem>);
  return (
    <ButtonDropdown direction="left" isOpen={context?.templateStore.isOpen} toggle={() => {context?.templateStore.toggleStore()}}>
        <DropdownToggle caret>
        {context?.templateStore.seletectTemplate?.name}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Templates</DropdownItem>
        <DropdownItem divider />
        {dropdownItems}
      </DropdownMenu>
    </ButtonDropdown>
  );
});

export default TemplateDropdown;