import React, { Component, createContext } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Demo from './components/demo/Demo';
import { RootStore } from './stores/root/RootStore';

import './App.scss';

export const RootStoreContext = createContext<RootStore | null>(null);

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <RootStoreContext.Provider value={new RootStore()}>
        <Layout>
          <Route path='/' component={Demo} />
        </Layout>
      </RootStoreContext.Provider>
    );
  }
}
