import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { RootStoreContext } from "../../App";

import "./ZoomPanel.scss";

const ZoomPanel = observer(() => {
    const context = useContext(RootStoreContext);
    if (!context) {
        return <React.Fragment />;
    }

    return (
        <div className="zoom-actions">
            <Form>
                <FormGroup>
                    <img src={require("../../assets/svg/search-minus.svg")} onClick={() => context.templateStore.decreaseZoomLevel()} alt="decrease zoom" />
                    <InputGroup>
                        <Input type="number" onChange={(e) => context.templateStore.setZoomLevel(e.target.value)} value={context.templateStore.zoomLevel} />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                    <img src={require("../../assets/svg/search-plus.svg")} onClick={() => context.templateStore.increaseZoomLevel()} alt="increase zoom" />
                </FormGroup>
            </Form>
        </div>
    );
});

export default ZoomPanel;