import { ITemplate } from "../stores/template/TemplateStore";

export type DocumentFormat = "Html" | "Pdf" | "Word";
export interface ExecutionRequest{
  templateId: string;
  executionData: string;
  format: DocumentFormat;
}

export class PortalService {
    public async fetchTemplatesAsync(){
        const response = await fetch("Portal");
        return await response.json() as ITemplate[];
    }

    public async executeTemplate(templateId: string, executionData: string): Promise<string>{
        const response = await fetch("Portal/ExecuteTemplate", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({ templateId, executionData}) 
          });

          return response.text();
    }

    public async downloadTemplate(request: ExecutionRequest, fileName: string){
      const response = await fetch("Portal/DownloadTemplate", {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', 
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(request) 
      });
      const blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove(); 
    }
}