import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import TemplateDropdown from './template-dropdown/TemplateDropdown';
import { XpertdocLogo } from '../assets/svg/XpertdocLogo';

import './NavMenu.scss';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props: any) {
    super(props);

    this.state = {
      collapsed: true
    };
  }

  render() {
    return (
      <header>
        <Navbar>
            <NavbarBrand tag={Link} to="/"><XpertdocLogo height={40}/></NavbarBrand>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <TemplateDropdown/>
              </NavItem>
            </ul>
        </Navbar>
      </header>
    );
  }
}
