import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../../App";

const PortalFrame = observer(() => {
    const ref = React.createRef<HTMLIFrameElement>();
    const context = useContext(RootStoreContext);

    useEffect(() => {
        if (ref.current){
            context?.templateStore.enableEventListener(ref.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let url: string = "";
    const selectedTemplate = context?.templateStore.seletectTemplate;
    if (selectedTemplate){
        url = selectedTemplate.executionUrl;
    }
    
    return <iframe title={"Portal"} style={{width: "100%", height: "100%", borderStyle: "none"}} src={url} ref={ref} />;
});

export default PortalFrame;